import React from 'react';
import LazyLoad from 'react-lazyload';

const LazyImage = (props) => {
  return (
    <LazyLoad>
      <img alt="" {...props} />
    </LazyLoad>
  );
};

export default LazyImage;
