import LazyImage from '@components/LazyImage';
import VnrLink from '@components/VnrLink';
import { Col, Row } from 'antd';
import React, { Fragment } from 'react';

import style from './404.module.scss';

const Page404 = () => (
  <Fragment>
    <Row className={style['page-404']}>
      <Col
        style={{
          padding: 0,
          height: 'calc(100vh - 3px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        md={24}
      >
        <div className={style['logo']}>
          <LazyImage
            style={{ width: '252px' }}
            src='/images/logo.png'
            alt=""
          />
        </div>
        <p className={style['zoom-area']}>
          <b> Không tìm thấy</b> đường dẫn này{' '}
        </p>
        <section className={style['error-container']}>
          <span>4</span>
          <span>
            <span className={style['screen-reader-text']}>0</span>
          </span>
          <span>4</span>
        </section>
        <div className={style['link-container']}>
          <VnrLink target="" to="/" className={style['more-link']}>
            TRANG CHỦ
          </VnrLink>
        </div>
      </Col>
    </Row>
  </Fragment>
);

export default Page404;
